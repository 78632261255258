@import 'design/template/mixin_templates';
@import "design/variables";

/*-----------------------------
    GENERAL
-------------------------------*/

body {
    background-repeat: repeat;
    margin: 0;
    font-size: 16px;
    line-height: 1.28;
    font-family: helvetica, arial, sans-serif;
    overflow-x: hidden;
}

#wrapper {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

@include titreBaseSize();

#bloc-fil-title {
    h1, .h1, h2, .h2 {
        padding: 20px 0 5px;
    }
}

.produits img {
    margin: 0;
}

img {
    max-width: 100%;

    /* Pour tinyMCE */
    height: auto !important;
}

iframe {
    max-width: 100%;
}

/*-----------------------------
    HEADER
-------------------------------*/
.cmonsite-panier-3 #main-conteneur header {
    z-index: auto;
}

header {
    //z-index: 10;
    position: relative;

    #blocContent {
        width: 100%;
    }

    #headercontent {
        position: relative;
        max-width: 1020px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        min-height: 150px;
    }

    .logo {
        position: relative;
        display: inline-block;
    }

    .logo img {
        max-height: 150px;
        height: auto !important;
    }

    .slogan {
        position: absolute;
        left: 250px;
        top: 40px;
        text-align: left;
        width: auto;
    }
}

/*-----------------------------
    MENU
-------------------------------*/

header .menu {
    position: relative;
    box-sizing: border-box;
    max-width: 1020px;
    margin: 0 auto;
    padding: 0 20px;

    // Effet d'ombre sur les côtés du menu

    &::after {
        content: '';
        height: calc(100% + 10px);
        top: 0;
        width: 100%;
        position: absolute;
        z-index: -1;
        box-shadow: 0 20px 0 0 #fff, 0 -20px 0 0 #fff, 0 0 10px 0 rgba(0, 0, 0, .4);
        right: 0;
    }
}

//Avec le template 2 panier

.cmonsite-index-diaporama-2,
.cmonsite-index-diaporama-4 {
    header .menu {
        margin-bottom: 15px;
        &::after {
            height: 100%;
            box-shadow: 0 -20px 0 0 #fff, 0 0 10px 0 rgba(0, 0, 0, .4);;
        }
    }
}

.btn-search {
    box-shadow: 4px 0 10px 0 rgba(0, 0, 0, .2);
}

/*-----------------------------
    SLIDER
-------------------------------*/

.wrapper {
    width: 960px;
    margin: 0 auto;
}

// On modifie un peu la structure globale du template si on affiche un slider full width

.cmonsite-index-diaporama-2,
.cmonsite-index-diaporama-4 {
    .wrapper-content {
        width: 100%;
        max-width: none;
        padding: 0;
        box-shadow: none;

        #wrapper {
            max-width: 1020px;
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
            margin-top: 15px;
        }
    }
}

.accueil_diaporama_template {
    width: 100%;
    margin: 0 auto;

    // Navigation du slider (boutons et fleches)

    .bx-wrapper {
        margin-bottom: 0;

        .bx-pager {

            bottom: 15px;

            .bx-pager-item > a {
                border-radius: 2px;
            }
        }
    }

    a.bx-prev {
        left: 30px;
    }

    a.bx-next {
        right: 30px;
    }

    a.bx-prev {
        text-align: center;
        font-size: 0;
        width: 20px;
        opacity: 0;
        transition: all .2s ease;
        text-indent: 0;
        background: none;

        &::before {
            content: "\f053";
            font-family: FontAwesome, sans-serif;
            font-size: 35px;
            display: inline-block;
            float: left;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }

        .fa {
            display: none;
        }
    }

    a.bx-next {
        text-align: center;
        font-size: 0;
        width: 20px;
        opacity: 0;
        transition: all .2s ease;
        text-indent: 0;
        background: none;

        &::before {
            content: "\f054";
            font-family: FontAwesome, sans-serif;
            font-size: 35px;
            display: inline-block;
            float: right;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }

        .fa {
            display: none;
        }
    }

    // Position des éléments

    .slide .slide-text .content-slide-text {

        > p {
            bottom: 110px;
            left: 20px;
            right: 20px;
            font-size: 1.1rem;
        }

        > a {
            border-radius: 5px;
            font-size: 1.1rem;
            top: auto;
            bottom: 45px;
            margin-bottom: 0;
        }

        .addcommentaires ~ .slider-see-more {
            right: 105px;
        }
    }

    &:hover {
        .previous, .next, .bx-prev, .bx-next {
            opacity: 1;
        }
    }

    // Affichage particulier template slider 1

    &.accueil_diaporama_template_1 {
        min-height: 0;

        .bx-viewport, .slide {
            max-width: 960px;
        }
    }

    // Affichage particulier template slider 2

    &.accueil_diaporama_template_2 {
        width: 100%;
        margin-bottom: 0;
        padding: 0 0 10px 0;
        min-height: 0;

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 125px;
                left: auto;
                right: 40px;
            }

            > a {
                top: auto;
                bottom: 45px;
                padding: 20px;
                width: auto;
                font-size: 1.1rem;
            }

            .addcommentaires ~ .slider-see-more {
                right: 135px;
            }
        }
    }

    // Affichage particulier template slider 3

    &.accueil_diaporama_template_3 {
        min-height: 0;

        .bx-controls-auto {
            display: none;
        }

        #bx-pager {
            background: rgba(0, 0, 0, 0.8);

            > a {
                opacity: 0.3;

                &.active, &:hover {
                    opacity: 1;
                }
            }
        }
    }

    // Affichage particulier template slider 4
    &.accueil_diaporama_template_4,
    &.accueil_diaporama_template_6 {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        min-height: 0;

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 120px;
                right: 40px;
                left: 40px;
            }

            .addcommentaires ~ .slider-see-more {
                right: 125px;
                padding: 20px;
                box-sizing: border-box;
                width: auto;
            }
        }
    }

    // Affichage particulier template slider 5

    &.accueil_diaporama_template_5 {

        min-height: 0;
        padding: 0;

        .slide .slide-text .content-slide-text {

            > p {
                bottom: 65px;
                right: 15px;
                left: 15px;
            }

            > a {
                bottom: 15px;
                right: 20px;
            }

            .addcommentaires ~ .slider-see-more {
                right: 95px;
            }
        }
    }
}

/*-----------------------------
    CONTENU
-------------------------------*/

.wrapper-content {
    position: relative;
    box-sizing: border-box;
    max-width: 1020px;
    margin: 0 auto;
    padding: 0;
    box-shadow: -1px 10px 10px 1px rgba(0, 0, 0, 0.3);
}

.content {
    min-height: 400px;
    padding-bottom: 30px;
}

.container {
    display: block;
    width: 960px;
    margin: 0 auto;
}

.content .page {
    min-height: 400px;
    padding: 0 10px;
    font-size: 12px;
    p {
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 15px;
    }
}

h2, .h2 {
    padding: 10px 0;
    font-size: 18px;
}

.page h2,
.page .h2 {
    margin: 0;
}

.title_section {
    margin: 0 20px;
}

.title_section a {
    text-decoration: none;
}

.title_section a:hover {
}

.content #fiche-produit .wrap-description h3,
.content #fiche-produit .wrap-description .h3 {
    margin: 0;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    margin: 0;
    padding-bottom: 5px;
    font-size: 14px;
}

h5, .h5 {
    border-bottom: 1px dashed;
    margin: 0;
    font-size: 12px;
    padding-bottom: 5px;
}

/*-----------------------------
    NAVIGATION
-------------------------------*/

#nav-principal {
    z-index: 49;
    position: relative;
    a{
        text-decoration: none;
    }
    > ul {
        margin: 0 auto;
        padding: 0;

        > li {
            display: block;
            background: none repeat scroll 0 0;
            position: relative;

            &:last-child {
                border-bottom: none;
            }

            > ul {
                left: 0;
            }

            ul {
                padding: 0;
                position: absolute;
                top: 55px;
                z-index: 50;
                opacity: 0;
                display: none;
                min-width: 100%;
            }
        }
    }

    ul li {
        float: left;
        list-style: none;
        padding-bottom: 11px;
        padding-top: 10px;

        &:hover {
            > ul {
                display: block;
                opacity: 1;
            }
        }

        li a:hover {
            transition: all .1s linear;
        }

        &.actif ul li {
            margin-left: 0;
        }

        a {
            font-size: 16px;
            line-height: 34px;
            padding: 0 15px;
            display: inline-block;
            vertical-align: bottom;
        }

        ul li {
            list-style: none;
            position: relative;
            padding: 0;
            display: block;
            opacity: 1;
            float: none;

            a {
                padding: 9px 26px;
                font-size: 12px;
                display: block;
                white-space: nowrap;
            }
        }
    }
    ul ul ul {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
        padding: 0;
    }
}

// Fils d'ariane

nav.arianne {
    border-bottom: 1px dashed;

    ul {
        margin: 0;
        padding: 7px 0 5px 7px;
    }

    li {
        list-style: none;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 25px;
        margin: 0 15px 0 0;
        font-size: 12px;

        a:hover, a.actif {
            text-decoration: underline;
        }
    }
}

/*-----------------------------
   BOUTIQUES
-------------------------------*/

.produit_etiquette {
    width: 100%;
}

.produit_etiquette2 {
    bottom: 0;
}

.container-etiquette {
    position: absolute;
    left: 0;
    right: 10px;

    .produit_etiquette {
        padding: 10px 0;
        box-sizing: border-box;

        .h3, h3 {
            padding-top: 0;
            font-size: 1rem;
        }
    }
}

.template_boutique_all .produits {
    box-sizing: border-box;
    padding: 10px;

    .mask {
        background-color: transparent;
    }

    &:hover .zoom {
        background: transparent;
        opacity: 1;
    }

    .zoom {
        border-radius: 0;
        position: absolute;
        background: transparent;
        box-sizing: border-box;
        margin: 0;
        text-align: center;
        &:hover {
            opacity: 1;
            background: transparent;
        }

        // Bouton "plus de détails" au hover
        &::before {
            display: none;
        }

        .button {
            display: inline-block !important;
            position: relative;
            top: 100px;
            transform: translateY(-50%);
            z-index: 2;
            font-size: 12px;
            margin-top:0;
        }

        &::after {
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            display: block;
            opacity: 0.3;
            z-index: 1;
        }
    }

    .productContentWrap {
        text-align: center;
    }

    .nomprod {
        font-size: 19px;
        margin: 5px 0;
        padding: 5px 0;
        text-align: center;

        a {
            text-decoration: none;
        }
    }

    .prix {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0;
        text-align: center;
        box-sizing: border-box;
        width: 100%;

        .oldprix, .prixprod {
            float: none;
        }

        .oldprix {
            font-size: 13px;
            order: 2;
            text-decoration: line-through;
            white-space: nowrap;
            min-width: 90px;
            flex: 1 1 auto;
        }

        .prixprod {
            font-size: 18px;
            order: 1;
            min-width: 90px;
            flex: 1 1 auto;
        }
        .prixmention{
            order: 3;
            flex: 1 1 auto;
        }
    }

    .attr {
        position: relative;
        width: 100%;
        float: none;
        text-align: center;
        min-height: 30px;
        margin: 10px 0;
        box-sizing: border-box;

        &:empty {
            min-height: 0;
        }

        select {
            border-radius: 4px 4px 4px 4px;
            padding: 1px 0 1px 0;
            height: 26px;

            position: relative;
            float: none;
            margin: 0 auto;
            cursor: pointer;

            left: auto;
            width: 80%;
        }
    }

    .addbasket, .hors-stock {
        box-sizing: border-box;
        float: none;
        text-align: center;
        margin: 10px auto;

        font-size: 14px;
        width: 90%;
        padding: 10px;
        display: inline-block;
    }

    .addbasket {
        cursor: pointer;
        .icon-cart {
            display: inline-block;
            height: 30px;
            left: 20px;
            position: absolute;
            top: 0;
            width: 30px;
        }
    }

    .hors-stock {
        width: 100%;
    }

    .remise {
        display: block;
        font-size: 18px;
        font-weight: bold;
        height: auto;
        padding: 10px;
        position: absolute;
        right: 10px;
        top: 60px;
        white-space: nowrap;
        width: auto;
        z-index: 5;
        left: auto;
    }

    .desc {
        font-size: 12px;
    }
}



// Affichages particuliers boutiques accueil

.template_boutique_accueil {

    // Affichages particuliers boutiques accueil 1
    &.accueil_boutique_template_1 .produits {

        &:hover .mask a.zoom {
            opacity: 1;
        }

        .container-etiquette {
            left: 0;
            right: 0;
        }

        .remise {
            left: auto;
            font-size: 12px;
        }

        .addbasket, .hors-stock {
            font-size: 12px;
            margin: 10px auto;
        }
    }

    // Affichages particuliers boutiques accueil 2
    &.accueil_boutique_template_2 .produits {
        text-align: center;
        .container-etiquette {
            left: 0;
            right: 0;
        }

        img {
            width: 100%;
        }
    }
}

// Affichages particuliers boutiques
.template_boutique {

    // Affichages particuliers boutiques 2
    &.template_boutique_2 .produits {

        .zoom::before {
            font-size: 20px;
            line-height: 30px;
            width: 175px;
        }

        .attr {

            select {
                right: auto;
            }
        }

        .addbasket, .hors-stock {
            display: inline-block;
            font-size: 20px;
        }
    }

    // Affichages particuliers boutiques 3
    &.template_boutique_3 .produits {

        .produits .zoom::before {
            width: 130px;
            font-size: 15px;
        }

        .remise {
            right: auto;
        }

        .container-etiquette {
            width: 45%;

            position: absolute;
            left: 0;
            right: auto;
            top: 0;
            padding: 10px 0 0 10px;
            box-sizing: border-box;
            z-index: 5;
        }

        .productImageWrap {
            width: 45%;
            box-sizing: border-box;
        }

        .productContentWrap {
            width: 55%;
            box-sizing: border-box;
            padding: 0 20px;
            text-align: left;

            .nomprod {
                margin-top: 0;
                padding-bottom: 15px;
                margin-bottom: 0;
            }

            .desc {
                font-size: 15px;
            }

            .prix {
                display: inline-block;
                text-align: left;
                min-height: 30px;
                line-height: 30px;

                .prixprod {
                    font-size: 1.3rem;
                    float: left;
                }
            }

            .attr {
                text-align: left;

                select {
                    height: 30px;
                    width: auto;
                }
            }

            .addbasket, .hors-stock {
                display: inline-block;
                position: relative;
                width: auto;
                max-width: 90%;
                bottom: auto;
            }
        }
    }

    // Affichages particuliers boutiques 4
    &.template_boutique_4 .produits {

        .productImageWrap {
            position: relative;
        }

        .remise {
            display: none;
        }

        .container-etiquette {
            top: 10px;
            left: 10px;
            position: absolute;
            margin-top: 0;
            margin-bottom: 15px;
        }

        .productContentWrap {

            .desc {
                text-align: left;
            }
            .attr {
                padding: 0;
            }
        }
    }

    // Affichages particuliers boutiques 5
    &.template_boutique_5 .produits {

        .productImageWrap {
            margin-bottom: 20px;
        }

        .remise {
            display: none;
        }

        .container-etiquette {
            padding: 10px 0 0 10px;
            left: 0;
            top: 0;
        }

        .zoom::before {
            font-size: 10px;
            line-height: 10px;
        }

        .prix {
            display: flex;
            flex-direction: column;
            width: 45%;

            .oldprix {
                order: 2;
                margin: 5px 0;
            }

            .prixprod {
                order: 1;
                margin: 5px 0;
            }
        }

        .attr {
            display: block;
            clear: both;
            select {
                width: auto;
            }
        }

        .remise {
            right: auto;
        }

        .addbasket, .hors-stock {
            width: 100%;
        }
    }
}

/*-----------------------------
   FICHE PRODUIT
-------------------------------*/

// On cache le titre de la page sur les fiches produit pour éviter le doublon de nom produit

.produit_etiquette h3,
.produit_etiquette .h3 {
    margin: 0 !important;
    padding: 0;
}

.cmonsite-ficheproduit #bloc-fil-title {
    h1, .h1, h2, .h2 {
        display: none;
    }
}

// Structure globale fiche produit

.template_fiche_produit {

    #fiche-produit {
        padding-top: 15px;
        h3, .h3 {
            margin-bottom: 10px;
        }

        .wrap-images {
            box-sizing: border-box;
            padding: 10px;

            .grande-image {
                box-sizing: border-box;
            }

            .previews {
                ul li {
                    box-sizing: border-box;
                }
            }

            .remise-produit {
                display: block;
                height: auto;
                border-radius: 0;
                top: 60px;
                padding: 10px;
                width: auto;
            }
        }

        .wrap-description {

            .radio_unique {
                cursor: pointer;
                margin: 10px 10px 10px 0;
                border-radius: 0;
                box-shadow: none;
            }

            .radio_color {
                box-shadow: none;
                border-radius: 5px;
                margin: 5px;
                border: 1px solid rgba(0, 0, 0, .1);

                &:hover, &.radio-color-checked {
                    border: 1px solid rgba(0, 0, 0, .3);
                }
            }

            .declinaison-checked {
                > .radio_color, .radio_unique {
                    opacity: .3;
                    &:hover {
                        opacity: 1;
                    }
                }
                > .radio-color-checked, > .radio-checked {
                    opacity: 1;
                }
            }

            .prix {
                height: 30px;
                line-height: 30px;

                .prixprod {
                    float: left;
                    margin-right: 15px;
                }
            }

            select {
                min-height: 30px;
                padding: 5px;
            }

            .row {
                margin: 0 auto 10px;
            }

            .note-produit {
                margin: 10px 0;
            }

            .pro-pannier {
                display: flex;
                flex-direction: column;
            }

            .declinaison {
                order: 1;
                margin: 0 0 10px 0;
            }

            .bloc-quantite {
                order: 3;
                width: 100%;
                margin-bottom: 14px;

                .bt-quantity{
                    height: auto;
                }

                span.notification_product {
                    display: block;
                    width: 100%;
                }
            }

            .ajouter-panier {
                &.block-price {
                    order: 2;
                }
                &.block-addbasket {
                    order: 4;
                }

                .triangle-ficheprod {
                    display: none;
                }

                .addbasket {

                    display: inline-block;
                    width: auto;
                    line-height: 20px;

                    .fa-shopping-cart {
                        margin-right: 10px;
                    }
                }
            }

            .details-ficheprod a.button {
                display: inline-block;
                width: auto;
            }

            .sociaux {
                padding: 17px 0;
            }
        }

        .bloc-description-detaille {
            box-sizing: border-box;
        }

        .wrap-details.bloc-avis-clients {

            .button {
                width: auto;

                .fa-comment {
                    margin-right: 10px;
                }
            }
        }

        .wrap-details h4,
        .wrap-details .h4 {
            border-bottom: 1px dashed;
        }

        h4, .h4 {
            margin: 10px 0;
        }

        .bloc-avis-clients {

            .moyenne_etoiles {
                margin: 0 15px;
            }

            .msg-informations {
                width: 100%;
                padding-bottom: 10px;
                margin-bottom: 20px;

                .noteComment {
                    float: right;
                    font-size: 20px;
                }

                span {
                    display: inline-block;
                }

                .time {
                    margin-right: 10px;
                    font-size: .9rem;
                }

                .msg-username {
                    font-weight: bold;
                }
            }

            .button {
                font-size: 15px;
                border: none;
            }
        }

        .previous, .bx-prev {
            text-align: center;
            font-size: 0;
            width: 20px;
            opacity: 0;
            transition: all .2s ease;
            text-indent: 0;
            background: none;
            left: 10px;

            &::before {
                content: "\f053";
                font-family: FontAwesome, sans-serif;
                font-size: 22px;
                display: inline-block;
                float: left;
            }

            .fa {
                display: none;
            }
        }

        .next, .bx-next {
            text-align: center;
            font-size: 0;
            width: 20px;
            opacity: 0;
            transition: all .2s ease;
            text-indent: 0;
            background: none;
            right: 10px;

            &::before {
                content: "\f054";
                font-family: FontAwesome, sans-serif;
                font-size: 22px;
                display: inline-block;
                float: right;
            }

            .fa {
                display: none;
            }
        }

        &:hover {
            .previous, .next, .bx-prev, .bx-next {
                opacity: 1;
            }
        }
    }

    // Affichage particulier template fiche produit 1

    &.template_fiche_produit_1 #fiche-produit {

        .wrap-images {

            .grande-image {
                margin: 0 0 10px 0;
            }
        }

        .container-etiquette {
            width: 100%;
        }

        .wrap-description {

            .declinaison-attribute-group,.product_options-group {
                margin: 5px 0;
            }

            .ajouter-panier .addbasket {
                margin-top: 3px;
            }
        }
    }

    // Affichage particulier template fiche produit 2

    &.template_fiche_produit_2 #fiche-produit {

        h1, .h1 {
            font-size: 1.5rem;
            margin: 10px 0;
        }

        .wrap-images {
            padding-top: 10px;

            .remise-produit {
                right: 0;
                left: auto;
                top: 0;
            }
        }

        .wrap-description {
            width: 35%;
            box-sizing: border-box;
            padding: 0 0 0 15px;

            #produit-comment .commentFicheProd {
                padding-right: 0;
            }

            .details-ficheprod {
                display: none;
            }

            .prix {
                display: flex;
                flex-direction: row;

                .oldprix {
                    order: 2;
                }

                .prixprod {
                    order: 1;
                }
            }

        }

        .wrap-details {
            padding: 0;

            &.bloc-avis-clients .msg-informations {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .grande-image {
            border: none;
        }
        .ajouter-panier {
            .addbasket {
                display: block;
            }
        }

        .bloc-description-detaille {
            padding: 10px 0;

            h4, .h4 {
                padding-bottom: 10px;
            }

            p {
                margin: 10px 0;
                text-align: justify;
            }
        }

        .noteGlobale {
            box-sizing: border-box;
            padding: 10px;
        }

        #addcomment {
            padding: 10px;
        }
    }

    // Affichage particulier template fiche produit 3

    &.template_fiche_produit_3 #fiche-produit {

        h1, .h1 {
            font-size: 1.5rem;
            margin: 10px 0;
        }

        .wrap-images {
            border: none;

            .triangle-ficheprod {
                //display: none;
            }
        }

        .prix {
            width: 100%;
            height: 50px;
            line-height: 50px;

            .oldprix, .prixprod {
                display: inline-block;
                width: auto;
                margin: 0 10px;
            }
        }

        .wrap-description {

            .bloc-quantite {
                order: 2;
                margin-top: 8px;
                width: 100%;

            }

            .ajouter-panier {
                order: 3;
            }

            .declinaison {
                order: 4;
            }

            .prix {
                order: 1;
                width: 100%;
            }

            .declinaisons-achat .pro-pannier .ajouter-panier .addbasket {
                width: auto;
                margin: 3px 0 0 120px;

                .fa-shopping-cart {
                    margin-right: 10px;
                }
            }

            .details-descr-com {
                .side-tabs {
                    width: 100%;

                    > a {
                        width: calc(100% / 3);
                        box-sizing: border-box;
                        clear: none;
                        display: inline-block;
                        border-radius: 0;
                        border-bottom: none;
                        border-top: none;
                        border-left: none;
                        margin: 0;
                        float: left;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }

                .in-onglet {
                    width: 100%;
                    padding: 15px 15px 0;
                    margin-left: 0;
                }
            }

            .sociaux {
                border-bottom: none;
            }
        }

        .bloc-avis-clients .noteGlobale,
        .bloc-avis-clients #addcomment {
            background: none;
        }
    }
}



#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        line-height: 20px;
        .fa {
            margin-right: 10px;
        }
    }

    > .button + .button {
        margin-left: 15px;
    }

}


.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}



/*-----------------------------
   PANIER
-------------------------------*/

.template-panier {
    &.template-panier-2 {
        background: transparent;
        border: none;

        #header-panier {
            height: 55px;
            line-height: 35px;
        }

        .count-live {
            margin-top: 8px;
        }

        .paniertoggle {
            float: none;

            .fa {
                float: none;
                margin-left: 10px;
            }
        }

        #paniercontent {
            border: none;
            box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.3);
        }
    }
    &.template-panier-3 {
        background: transparent;
        border: none;

        .paniertoggle {
            float: none;

            .fa {
                float: none;
                margin-left: 10px;
            }
        }

        #paniercontent {
            border: none;
        }
    }
}

/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/

.template_album {

    .galerie {
        box-sizing: border-box;
        border-top: 2px solid;
        float: left;
        position: relative;

        > a:first-child {
            display: inline-block;
            z-index: 2;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.2s linear;
                z-index: 10;
            }
        }

        &:hover {
            .commentP p {
                transition: all 0.1s ease-out 0.2s;
                opacity: 1;
            }
            .addcommentaires {
                transition: all 0.1s ease-out 0.2s;
                opacity: 1;
            }
        }

        .commentP p {
            width: auto;
            margin: 0 auto;
            display: block;
            box-sizing: border-box;
            font-size: 1.1rem;
            line-height: 1.4rem;
            text-align: center;
            background: transparent;
            padding: 10px 10px 0 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            left: 10px;
            right: 10px;
            opacity: 0;

            &:after {
                content: '';
                display: block;
                width: 50%;
                height: 1px;
                margin: 10px auto 0;
                background: #fff;
            }
        }

        .addcommentaires {
            border: none;
            font-size: 1rem;
            font-weight: bold;
            padding: 0 0 5px;
            display: block;
            position: absolute;
            top: 45%;
            width: auto;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: #000;
            z-index: 20;
            opacity: 0;
        }

        .commentP ~ .addcommentaires {
            top: 55%;
        }
    }

    &.template_album_1 {

        .galerie {

            .commentP {
                position: static;
                bottom: auto;
                left: auto;
                width: auto;

                p {
                    bottom: auto;
                }
            }

            a.addcommentaires {
                text-align: center;
            }
        }
    }

    &.template_album_2 {

        .galerie_gutter {
            width: 0;
        }

        .galerie {
            &:hover {
                transform: none;
                box-shadow: none;
                img {
                    transform: none;
                }
            }
        }
    }

    &.template_album_3 {
        .galerie {
            border: none;
            .commentP p {
                width: auto;
                position: absolute;
            }
        }
    }

    &.template_album_4 .galerie,
    &.template_album_5 .galerie {
        > a:first-child {
            display: block;
        }
        .commentP {
            p {
                position: relative;
                transform: none;
                padding: 0;
                text-align: left;
                left: auto;
                right: auto;
                top: auto;
                bottom: auto;
                opacity: 1;
                &::after {
                    display: none;
                }
            }

            & ~ .addcommentaires {
                top: 45%;
            }
        }
    }
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.livre_dor {
    padding: 0 10px;

    .buttonLivre .button {
        margin: 10px 0;
        display: inline-block;
    }

    > h2, .h2 {
        margin: 0 20px 20px 0;
    }

    &.template_livreor_1 {

        .message_lo {
            overflow: hidden;
            padding: 40px 20px 45px;
            position: relative;

            &::before {
                content: "\201D";
                font-size: 110px;
                left: 15px;
                position: absolute;
                top: -15px;
            }

            h4, .h4 {
                bottom: 26px;
                font-size: 10px;
                margin: 0;
                position: absolute;
            }

            .note {
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                position: absolute;
                right: 0;
                top: -7px;
                width: 70px;
            }

            .message {
                font-size: 13px;
                margin: 0 0 20px;
                min-height: 50px;
                position: relative;
            }
        }
    }

    &.template_livreor_3 {

        .list-messages .message_lo {
            background: transparent;

            .IDLO {
                position: relative;
                z-index: 5;

                .contenuMsgLO {
                    position: static;
                    z-index: 1;
                }
            }
        }

        .list-messages .message_lo:hover .contenuMsgLO .message::before,
        .list-messages .message_lo:hover .contenuMsgLO .message::after {
            opacity: 0.5;
        }
    }
}

/*-----------------------------
    PAGE DE CONTACT
-------------------------------*/
.contact.template_contact {

    .col > form > .col {
        padding: 0;
    }

    .form-group {

        label {
            margin-bottom: 5px;
        }

        input[type=text] {
            padding: 5px;
        }
    }

    .form input.button[type="submit"] {
        margin: 0;
    }

    // Affichage particulier template 1
    &.template_contact_1 {
        .form input.button[type="submit"] {
            float: left;
        }
    }

    // Affichage particulier template 2
    &.template_contact_2 {
        .row form .form-group input[type='text'] {
            background: none;
        }
    }

    // Affichage particulier template 3
    &.template_contact_3 {
        .row form .form-group input[type="text"] {
            padding: 10px 10px 10px 35px;
        }

        .form input.button[type="submit"] {
            float: left;
        }
    }

    // Affichage particulier template 5
    &.template_contact_5 {
        .inmap {
            top: 15px;
            left: 25px;
        }
    }
}

/*-----------------------------
    FOOTER
-------------------------------*/
footer {
    width: 100%;

    #pub {
        max-width: 1020px;
        text-align: center;
        margin: 30px auto;
    }

    .social-networks {
        position: absolute;
        max-width: 1020px;
        left: 0;
        right: 0;
        margin: -50px auto 0 auto;
    }

    .mention {
        text-align: center;
        display: block;
        padding: 10px 0;
        margin-top: 60px;

        a {
            text-decoration: none;
            font-size: 11px;
        }
    }
}

.facebook {
    position: absolute;
    top: 3%;
    text-align: center;
}

/*-----------------------------
    GESTION DES CLASSES OBJETS GENERALE
-------------------------------*/
/*
    * Gestion des photos gerer avec fancybox
    * sur les produits et galerie images
*/

.smiley img {
    margin: 0;
}

.button, .bouton, .links_p .bouton, .links_p .button, .produits .addbasket, .links_p a.addmsglo, .btn-search, input[type="submit"], .form-newsletter input[type="submit"], .addmsglo, .links_p .addmsglo {
    border-radius: 5px 5px 5px 5px;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 35px;
    border: none;
    display: inline-block;
}

#fiche-produit .produits .mask a {
    float: none;
    margin: 89px 0 0;
    display: inline-block;
}

.produits .addbasket {
    float: left;
}

.contact_text {
    margin: 0 20px;
    border-top: 1px solid;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#resume .block-resume-price .col.s6,
    div#finish .block-resume-price .col.s6 {
        width: 100%;
    }

    div#resume .block-price .block-with-background,
    div#finish .block-price .block-with-background {
        border-left: none;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/

@media #{$medium-and-down} {

    // Général

    #wrapper {
        padding: 15px;
    }

    #wrapper,
    .wrapper-content,
    .wrapper-content .accueil_diaporama_template,
    footer {
        width: 100%;
        min-height: 0;
        max-width: none;
    }

    #minisite-sidebar,
    #block-flottant-search .btn-search {
        display: none;
    }

    .wrapper-content .accueil_diaporama_template.accueil_diaporama_template_1 .bx-viewport,
    .wrapper-content .accueil_diaporama_template.accueil_diaporama_template_1 .slide {
        max-width: 100%;
    }

    // Header

    header {
        width: 100%;
        min-height: 145px;

        .container-slogan {
            margin: 0;
        }

        .slogan {
            left: 200px;
            bottom: 10px;
            top: auto;
            margin: 0;
        }
    }

    .template-panier.template-panier-2 {
        right: 65px;

        #paniercontent {

            /*Hack pour passer au dessus du template panier*/
            right: -65px;
        }
    }

    #blocContent .template-panier.template-panier-3 {
        top: 15px;
        right: 75px;

        #panier {
            height: 50px;
        }
    }

    .container-slogan {
        margin: 15px 0;
    }

    // Menu

    header .menu.navigation_p {
        height: 50px;
        width: 50px;
        right: 15px;
        top: 15px;
        position: absolute;

        nav#nav-principal {
            background: inherit;
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0;
            padding: 0;

            a.menu_burger {
                width: 100%;
                height: 100%;
                padding-right: 0;
                display: inline-block;
                text-align: center;

                &::before {
                    content: '\f0c9';
                    font-family: fontAwesome, sans-serif;
                    font-size: 1.8rem;
                    line-height: 50px;
                }
            }

            > ul {
                background: inherit;
                min-width: 300px;
                max-width: 300px;
                > li ul {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }
            }

            ul {
                display: none;
                float: right;
                left: auto;

                li {
                    width: 100%;
                    padding: 0;
                    min-height: 50px;
                    border-radius: 0;
                    float: left;
                    box-sizing: border-box;

                    &.sousmenu_ouvert {
                        display: block;
                    }

                    &:hover {

                        ul li:hover {
                            background-color: transparent;
                        }
                    }

                    ul {
                        padding: 0;
                        top: 0;

                        li {
                            box-shadow: none;

                            &:hover {
                                box-shadow: none;
                            }

                            a {
                                height: auto;
                                min-height: 50px;
                                line-height: 50px;
                                padding: 0 15px;
                            }
                        }
                    }

                    a.navigation_link_p, a.sous_navigation_link_p {
                        width: 85%;
                        box-sizing: border-box;
                        margin: 0;
                        line-height: 50px;
                        display: inline-block;
                    }

                    &:hover ul {
                        display: block;
                        position: relative;
                    }

                    span.dropDown {
                        display: inline-block;
                        float: right;
                        height: 50px;
                        width: 15%;
                        text-align: center;
                        line-height: 50px;
                        font-size: 1rem;
                        color: inherit;
                    }
                }
            }
        }
    }

    // Affichage boutique template boutique 3
    #wrapper .template_boutique.template_boutique_3 .produits {

        .container-etiquette {
            width: 100%;
        }

        .productImageWrap {
            width: 100%;
            margin: 0;
            border: none;
        }

        .productContentWrap {
            width: 100%;
            text-align: center;

            .prix {
                text-align: center;

                .prixprod {
                    float: none;
                }
            }
        }
    }

    // Affichage boutique template boutique 5
    #wrapper .template_boutique.template_boutique_5 .produits {

        .productImageWrap {
            width: 100%;
        }

        .productContentWrap {
            width: 100%;
            text-align: center;

            .attr select {
                margin: 0 auto;
            }

            .addbasket {
                width: auto;
                margin: 0 auto;
                float: none;
            }
        }
    }

    // Affichage template fiche produit

    #wrapper .template_fiche_produit {

        .wrap-images {
            width: 100%;
            margin-bottom: 20px;
        }

        .wrap-description {
            width: 100%;

            h3, .h3 {
                margin: 0;
            }
        }

        &.template_fiche_produit_2 {
            .wrap-images {
                width: 100%;
            }

            .wrap-description {
                width: 100%;
                padding-left: 0;

                h1, .h1 {
                    margin: 0;
                }

                .container-etiquette {
                    display: none;
                }

                .ajouter-panier {
                    text-align: left;
                }

                .prix {
                    text-align: left;
                    width: 300px;
                    margin: 0;
                }

                .addbasket {
                    margin: 3px 0 0 120px;
                }
            }
        }
    }

    footer {

        .social-networks .facebook {
            margin-right: 0;
            width: 100%;
        }
    }
}

@media #{$small-and-down} {

    // Général

    #wrapper {
        padding: 5px;
    }

    // Header

    header {

        #blocContent {
            height: 100%;
        }

        .logo img {
            max-height: 80px;
            margin-top: 15px;
        }

        .slogan {
            position: relative;
            font-size: 1.2rem;
            left: auto;
            top: auto;
            bottom: auto;
            margin: 10px;
        }
    }

    // Affichage template boutique

    .template_boutique {

        &.template_boutique_3 {
            padding: 5px;
        }

        &.template_boutique_5 {

            .container-etiquette {
                width: auto;
            }

            .addbasket {
                display: inline-block;
            }
        }
    }

    // Affichage template fiche produit
    .template_fiche_produit {

        padding: 5px;

        .wrap-images {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        #addcomment #formulaire {
            .form-group {
                padding: 0 15px 0 0;
                min-width: 45%;

                &:nth-child(3) {
                    clear: left;
                }
            }
        }

        &.template_fiche_produit_2 {
            .productImageWrap {
                border: none;
            }
        }

        &.template_fiche_produit_3 {
            #fiche-produit .wrap-description .details-descr-com .side-tabs > a {
                font-size: 0;

                &::before {
                    font-size: 12px;
                }
            }
        }
    }

    .template_contact {
        padding: 5px;
    }

    /* Tableaux responsive */

    @import 'design/helper/responsive/mobile/mobile';
}